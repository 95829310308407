// CorporateModal.js

import React, { useState, useEffect } from "react"
import { Modal, Container, Row, Col, Form, Button, Spinner } from "react-bootstrap"
import { GoArrowLeft } from "react-icons/go"
import { Image } from "react-bootstrap"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import Corpuser from "../../assets/webImages/user.svg"
import { FaUserCircle } from "react-icons/fa"
import SearchModal from "./Searchmodal"
import { useNavigate } from "react-router-dom"
import { CiSearch } from "react-icons/ci"
import { Input } from "reactstrap"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { userAuthActions } from "store/userAuth/actions"
import { useDispatch } from "react-redux"
import LogoutModal from "webComponents/modals/LogoutModal"
import NavDropdown from "react-bootstrap/NavDropdown"
import TextBgColors, { getColorByLetter } from "../Common/TextBgColors"
import { useForm } from "react-hook-form"
import Select from "react-select"
// import { BlogContentActions } from "store/blogContent/actions"
import { corporateSubscriptionActions } from "../../store/corporateSubscription/actions"
import { actions } from "store/Countries/actions"

const CorporateModal = ({ show, handleClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  var [countryFullList, setCountryFullList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("")

  var [marketFullList, setMarketFullList] = useState([])
  const [selectedMarket, setSelectedMarket] = useState("")

  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { ProfileUserInfo } = useSelector(state => state.WebHomePageReducer)
  const [profileImage, setProfileImage] = useState("")
  const { buttonLoader } = useSelector(state => state.commonReducer);

  useEffect(() => {
    setProfileImage(ProfileUserInfo?.result?.Profile_Image || "")
  }, [ProfileUserInfo])

  const handleBackToSubscriptionClick = () => {
    handleClose()
    setShowSearchModal(false)
  }

  const handleLoginClick = () => {
    navigate("/web/login")
  }

  const handleSearchClick = () => {
    setShowSearchModal(true)
  }

  const handleCloseSearchModal = () => {
    setShowSearchModal(false)
  }

  const handleLogout = () => {
    setShowLogoutModal(true)
  }

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false)
  }

  const handleLogoutConfirmed = () => {
    // Perform logout logic
    if (tokenResponse?.result?.Type_Of_User === "User") {
      localStorage.removeItem("WEB_TOKEN")
      dispatch({
        type: userAuthActions.UPDATE_AUTH_DETAILS,
        payload: { isAuthenticated: false },
      })
    }
    setShowLogoutModal(false)
  }

  const onSubmit = data => {
    const lowercaseEmail = data.Email.toLowerCase();
    dispatch({
      type: corporateSubscriptionActions.GET_WEB_ADD_CORPORATE_DETAIL,
      payload: { ...data, Email: lowercaseEmail },
    })
  }

  // const { countryList , onlyCountryList} = useSelector(state => state.CountryReducer)
  const { countryList,onlyCountryList } = useSelector(state => state.CountryReducer)
  const { webCorporateResponse } = useSelector(
    state => state.corporateSubscriptionReducer
  )

  useEffect(() => {
    if (webCorporateResponse && webCorporateResponse?.statusCode === 200) {
      reset();
      setValue("Country", "");
      setValue("Market_Focus", "");

    }
  }, [webCorporateResponse, reset, setValue])

  // market-focus
  useEffect(() => {
    if (countryList?.result?.length > 0) {
      let array = countryList?.result?.map(val => ({
        id: val?.Country_Name,
        name: val?.Country_Name,
      }))
      setMarketFullList(array)
    }
  }, [countryList])

  const handleMarketChange = marketValue => {
    setSelectedMarket(marketValue)
  }

  // country
  useEffect(() => {
    if (onlyCountryList?.result?.length > 0) {
      let array = onlyCountryList?.result?.map(val => ({
        id: val?.Country_Name,
        name: val?.Country_Name,
      }))
      setCountryFullList(array)
    }
  }, [onlyCountryList])

  const handleCountryChange = selectedValue => {
    setSelectedCountry(selectedValue)
  }

  // useEffect(() => {
  //   dispatch({ type: actions.GET_COUNTRY_LIST })
  // }, [])

  useEffect(() => {
    dispatch({ type: actions.GET_ONLY_COUNTRY_LIST })
  }, [])

  const handleBookmarks = () => {
    navigate("/Save")
  }


  const UserDisplay = ({ firstName }) => {
    const bgColor = getColorByLetter(firstName?.charAt(0))

    return (
      <div className="login-link">
        <NavDropdown
          title={
            <div>
              <span className="login-text color-black">{firstName}</span>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="rounded-background-profile-img"
                  style={{ background: bgColor }}
                />
              ) : (
                <div
                  className="rounded-background"
                  style={{ background: bgColor }}
                >
                  {firstName?.charAt(0)?.toUpperCase()}
                </div>
              )}
            </div>
          }
          id="basic-nav-dropdown"
          className="profile-dropdown color-black"
        >
          <NavDropdown.Item href="/web/Profile">Profile</NavDropdown.Item>
          <NavDropdown.Item className="bookmark-nav" onClick={handleBookmarks}>
            BookMarks
          </NavDropdown.Item>
          <li><hr className="dropdown-divider" /></li>



          <NavDropdown.Item href="#" onClick={handleLogout}>
            Logout
          </NavDropdown.Item>


        </NavDropdown>
      </div>
    )
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      fullscreen
      className="corpuser-modal"
    >
      <Modal.Body>
        <Container>
          <Row className="align-items-center cor-user-heading">
            <Col xs="auto">
              <a href="/">
                <Image src={Logoimage} alt="Logo" className="logo-ad" />
              </a>
            </Col>
            <Col xs="auto" className="ms-auto">
              <div className="d-flex align-items-center">
                <CiSearch className="search-icon" onClick={handleSearchClick} />
                {tokenResponse ? (
                  // User is logged in
                  <UserDisplay firstName={tokenResponse?.result?.First_Name} />
                ) : (
                  // User is not logged in
                  <div className="login-link" onClick={handleLoginClick}>
                    <FaUserCircle className="login-icon color-black" />
                    <span className="login-text color-black d-none d-sm-inline">log in</span>
                  </div>
                )}
              </div>
            </Col>
            <LogoutModal
              show={showLogoutModal}
              handleClose={handleCloseLogoutModal}
              handleLogoutConfirmed={handleLogoutConfirmed}
            />
          </Row>
        </Container>
        {showSearchModal && (
          <SearchModal
            show={showSearchModal}
            handleClose={handleCloseSearchModal}
          />
        )}

        <Container className="corpuser-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={4} xs={12} className="signup-container">
                <p
                  className="back-to-login"
                  onClick={handleBackToSubscriptionClick}
                >
                  <GoArrowLeft /> back to subscription page
                </p>

                <h5 className="signup-header">corporate subscription</h5>
                <p className="signup-description">
                  please enter your details and we will get back to you
                </p>
              </Col>
              <Col className="d-flex justify-content-end corpuser-img">
                <img src={Corpuser} alt="corporate user" />
              </Col>
            </Row>
            <Row className="form-group-row">
              <Col lg={4} xs={12}>
                <Form.Group controlId="formFirstName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="First_Name"
                    {...register("First_Name", {
                      required: "First name is required",
                    })}
                    className={
                      errors.First_Name
                        ? "required-field form-control"
                        : "form-control"
                    }
                    placeholder="Enter your first name"
                  />
                  {errors.First_Name && (
                    <span className="invalid">
                      <small className="error-danger">
                        first name is required
                      </small>
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group controlId="formLastName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    last name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Last_Name"
                    {...register("Last_Name", {
                      required: "last name is required",
                    })}
                    className={
                      errors.Last_Name
                        ? "required-field form-control"
                        : "form-control"
                    }
                    placeholder="enter your last name"
                  />
                  {errors.Last_Name && (
                    <span className="invalid">
                      <small className="error-danger">
                        last name is required
                      </small>
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group controlId="formLastName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    Phone number
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter your phone number"
                    name="Phone_Number"
                    {...register("Phone_Number", {
                      required: "Phone number is required",
                      // pattern: {
                      //   value: /^\d{10}$/,
                      //   message: "Please enter 10 digits.",
                      // },
                    })}
                    className={
                      errors.Phone_Number
                        ? "required-field form-control"
                        : "form-control"
                    }
                  />
                  {errors.Phone_Number && (
                    <span className="invalid">
                      <small className="error-danger">
                        {errors.Phone_Number.message}
                      </small>
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-group-row">
              <Col lg={4} xs={12}>
                <Form.Group controlId="formFirstName" className="form-group">
                  <Form.Label className="form-label web-form-label">Email </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter your email"
                    name="Email"
                    {...register("Email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "invalid email format",
                      },
                    })}
                    className={errors.Email ? "required-field formcontrol" : "formcontrol"}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      setValue("Email", trimmedValue); // use setValue to update the form state
                    }}
                  />
                  {errors.Email && (
                    <span className="invalid">
                      <small className="error-danger">
                        {errors.Email.message}
                      </small>
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group controlId="formFirstName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    country
                  </Form.Label>
                  <select
                    name="Country"
                    value={watch("Country")} // Using watch to get the current value
                    onChange={e => handleCountryChange(e.target.value)}
                    {...register("Country", {
                      required: "Country is required",
                    })}
                    className={
                      errors.Country
                        ? "required-field form-control"
                        : "form-control"
                    }
                  >
                    <option value="" disabled className="custom-nav-link">
                      -- Select the country --
                    </option>
                    {countryFullList.map(country => (
                      <option key={country.id} value={country.id} className={(country.name === 'cocoa' || country.name === 'oil' || country.name === 'gold') ? 'optionclr' : ''}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.Country && (
                    <span className="error-message">
                      {errors.Country.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group controlId="formLastName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    Company name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Company_Name"
                    {...register("Company_Name", {
                      required: "company name is required",
                    })}
                    className={
                      errors.Company_Name
                        ? "required-field form-control"
                        : "form-control"
                    }
                    placeholder="enter your company name"
                  />
                </Form.Group>
                {errors.Company_Name && (
                  <span className="invalid">
                    <small className="error-danger">
                      company name is required
                    </small>
                  </span>
                )}
              </Col>
            </Row>
            <Row className="form-group-row">
              <Col lg={4} xs={12}>
                <Form.Group controlId="formLastName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    Number of Subscriptions Required
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="Expected_Users"
                    {...register("Expected_Users", {
                      required: "number of subscriptions is required",
                    })}
                    className={
                      errors.Expected_Users
                        ? "required-field form-control"
                        : "form-control"
                    }
                    placeholder="enter number of subscriptions"
                  />
                </Form.Group>
                {errors.Expected_Users && (
                  <span className="invalid">
                    <small className="error-danger">
                      number of subscriptions is required
                    </small>
                  </span>
                )}
              </Col>

              {/* <Col lg={4} xs={12}>
                <Form.Group controlId="formFirstName" className="form-group">
                  <Form.Label className="form-label custom-nav-link">
                    market focus
                  </Form.Label>
                  <select
                    name="Market_Focus"
                    value={watch("Market_Focus")} // Using watch to get the current value
                    onChange={e => handleMarketChange(e.target.value)}
                    {...register("Market_Focus", {
                      required: "market focus is required",
                    })}
                    className={
                      errors.Country
                        ? "required-field form-control"
                        : "form-control"
                    }
                  >
                    <option value="" disabled className="custom-nav-link">
                      -- Select --
                    </option>
                    {marketFullList?.map(country => (
                      <option key={country.id} value={country.id} className={(country.name === 'cocoa' || country.name === 'oil' || country.name === 'gold') ? 'optionclr' : ''}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.Market_Focus && (
                    <span className="error-message">
                      {errors.Market_Focus.message}
                    </span>
                  )}
                </Form.Group>
              </Col> */}

              <Col lg={4} xs={12}>
                <Form.Group controlId="formFirstName" className="form-group">
                  <Form.Label className="form-label web-form-label custom-nav-link">
                    How we can help you{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="How_To_Help"
                    {...register("How_To_Help", {
                      required: "how can you help field is required",
                    })}
                    className={
                      errors.How_To_Help
                        ? "required-field form-control"
                        : "form-control"
                    }
                    placeholder="Enter your query"
                  />
                  {errors.How_To_Help && (
                    <span className="invalid">
                      <small className="error-danger">
                        how can you help field is required
                      </small>
                    </span>
                  )}
                </Form.Group>
              </Col>

              <Col className="corp-submit custom-nav-link">
                <Button
                  className="corp-sub-but custom-nav-link "
                  variant="success"
                  type="submit"
                >
                  {buttonLoader ? <Spinner color="light" size="sm" /> : "Submit"}
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </Modal.Body>
    </Modal >
  )
}

export default CorporateModal
