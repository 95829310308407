import { takeEvery, call, all, put, delay } from "redux-saga/effects"
import { userAuthActions } from "./actions"
import axios from "axios"
import { API_URL } from "../../utils/constant"
import { encryptFunction } from "../../utils/cryptoFunctions"
import { commonActions } from "../common/actions"
import setAuthToken from "../../utils/setAuthToken"

export const userAuthSagas = function* () {
  yield all([
    yield takeEvery(userAuthActions.AUTH_LOGIN, authLogin),
    yield takeEvery(userAuthActions.VERIFY_TOKEN, verifyToken),
    yield takeEvery(userAuthActions.FOTGOT_PASSWORD, forgotPassword),
    yield takeEvery(
      userAuthActions.GET_RESET_YOUR_PASSWORD,
      getResetYourPassword
    ),
  ])
}

const authLogin = function* (data) {
  const { payload } = data
  const { navigate } = payload
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/login`, payload?.data)
    )
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })

      if (payload?.checked) {
        const encryptUser = encryptFunction(payload?.data)
        localStorage.setItem("Auth_User", encryptUser)
      } else {
        localStorage.removeItem("Auth_User")
      }
      if (result?.data?.Type_Of_User === "Super Admin") {
        localStorage.setItem("GFX_TOKEN", result?.data?.token)
        setAuthToken(result?.data?.token, 'Super_Admin');
        localStorage.setItem("GFX_Dashboard", "true")
        localStorage.setItem("SuperAdminLogin", Date.now())

        yield put({
          type: userAuthActions.VERIFY_TOKEN,
          payload: result?.data?.token,
        })

        // yield put({
        //   type: userAuthActions?.UPDATE_AUTH_DETAILS,
        //   payload: {
        //     isAuthenticated: true,
        //     route: "/dashboard",
        //     result: result?.data,
        //   },
        // })

        //new changes in token response

        yield put({
          type: userAuthActions?.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            route: "/dashboard",
            result: result?.data,
          },
        })
      }
      if (result?.data?.Type_Of_User === "Content Creator") {
        localStorage.setItem("GFX_TOKEN2", result?.data?.token)
        setAuthToken(result?.data?.token, 'Content_Creator');
        localStorage.setItem("GFX_Dashboard", "true")
        localStorage.setItem("CreatorLogin", Date.now())
        yield put({
          type: userAuthActions.VERIFY_TOKEN,
          payload: result?.data?.token,
        })
        // yield put({
        //   type: userAuthActions?.UPDATE_AUTH_DETAILS,
        //   payload: {
        //     isAuthenticated: true,
        //     route: "/creator-dashboard",
        //     result: result?.data,
        //   },
        // })

        //new token response for creator
        yield put({
          type: userAuthActions?.UPDATE_CREATOR_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            route: "/creator-dashboard",
            result: result?.data,
          },
        })
      }
      if (result?.data?.Type_Of_User === "Admin") {
        localStorage.setItem("GFX_TOKEN3", result?.data?.token)
        setAuthToken(result?.data?.token, 'Admin');
        localStorage.setItem("GFX_Dashboard", "true")
        localStorage.setItem("AdminLogin", Date.now())

        yield put({
          type: userAuthActions.VERIFY_TOKEN,
          payload: result?.data?.token,
        })

        // yield put({
        //   type: userAuthActions?.UPDATE_AUTH_DETAILS,
        //   payload: {
        //     isAuthenticated: true,
        //     route: "/dashboard",
        //     result: result?.data,
        //   },
        // })

        //new changes in token response

        yield put({
          type: userAuthActions?.UPDATE_ADMIN_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            route: "/admin-dashboard",
            result: result?.data,
          },
        })
      }
      if (result?.data?.Type_Of_User === "User") {
        yield put({
          type: userAuthActions.VERIFY_TOKEN,
          payload: result?.data?.token,
        })
        localStorage.setItem("WEB_TOKEN", result?.data?.token)
        setAuthToken(result?.data?.token, 'User');

        //token response for websie
        yield put({
          type: userAuthActions?.UPDATE_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            // route: "/",
            result: result?.data,
          },
        })
        yield delay(2000)
        window.location.href = "/"
      }
    } else {
      // sessionStorage.removeItem("GFX_TOKEN")
      // sessionStorage.removeItem("GFX_TOKEN2")
      // sessionStorage.removeItem("WEB_TOKEN")

      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "failed",
          message: result?.data?.message,
        },
      })

      if (payload?.loginFrom === "AdminOrCreator") {
        yield put({
          type: userAuthActions?.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
          payload: { isAuthenticated: false, result: result?.data },
        })
        yield put({
          type: userAuthActions?.UPDATE_CREATOR_AUTH_DETAILS,
          payload: { isAuthenticated: false, result: result?.data },
        })
        yield put({
          type: userAuthActions?.UPDATE_ADMIN_AUTH_DETAILS,
          payload: { isAuthenticated: false, result: result?.data },
        })
      }
      // else {
      //   yield put({
      //     type: userAuthActions?.UPDATE_AUTH_DETAILS,
      //     payload: { isAuthenticated: false, result: result?.data },
      //   })
      // }
    }
    yield delay(2000)
    yield put({
      type: commonActions.SET_ALERT,
      payload: { show: false, status: null, message: null },
    })
  } catch (err) {
    //commented recently
    // sessionStorage.removeItem("GFX_TOKEN")
    if (payload?.loginFrom === "AdminOrCreator") {
      localStorage.setItem("GFX_Dashboard", "false")
    }
    console.log(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}


//verify Token
const verifyToken = function* (data) {
  const { payload } = data

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/verify/token/${payload}`)
    )
    
    if (result?.data?.statusCode === 200) {
      if (result?.data?.result?.Type_Of_User === "Super Admin") {
        if (
          !localStorage.getItem("GFX_Dashboard") ||
          localStorage.getItem("GFX_Dashboard") !== "false"
        ) {
          localStorage.setItem("GFX_Dashboard", "true")
        }
        // yield put({
        //   type: userAuthActions.UPDATE_AUTH_DETAILS,
        //   payload: {
        //     isAuthenticated: true,
        //     route: "/dashboard",
        //     result: result?.data,
        //   },
        // })
        //new token response for super admin
        yield put({
          type: userAuthActions.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            route: "/dashboard",
            result: result?.data,
          },
        })
      }
      if (result?.data?.result?.Type_Of_User === "Content Creator") {
        if (
          !localStorage.getItem("GFX_Dashboard") ||
          localStorage.getItem("GFX_Dashboard") !== "false"
        ) {
          localStorage.setItem("GFX_Dashboard", "true")
        }
        // yield put({
        //   type: userAuthActions?.UPDATE_AUTH_DETAILS,
        //   payload: {
        //     isAuthenticated: true,
        //     route: "/creator-dashboard",
        //     result: result?.data,
        //   },
        // })

        //new token response for creator
        yield put({
          type: userAuthActions?.UPDATE_CREATOR_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            route: "/creator-dashboard",
            result: result?.data,
          },
        })
      }
      if (result?.data?.result?.Type_Of_User === "Admin") {
        if (
          !localStorage.getItem("GFX_Dashboard") ||
          localStorage.getItem("GFX_Dashboard") !== "false"
        ) {
          localStorage.setItem("GFX_Dashboard", "true")
        }
        // yield put({
        //   type: userAuthActions.UPDATE_AUTH_DETAILS,
        //   payload: {
        //     isAuthenticated: true,
        //     route: "/dashboard",
        //     result: result?.data,
        //   },
        // })
        //new token response for admin
        yield put({
          type: userAuthActions.UPDATE_ADMIN_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            route: "/admin-dashboard",
            result: result?.data,
          },
        })
      }
      if (result?.data?.result?.Type_Of_User === "User") {
        //token response for user
        yield put({
          type: userAuthActions?.UPDATE_AUTH_DETAILS,
          payload: {
            isAuthenticated: true,
            // route: "/",
            result: result?.data,
          },
        })

        // window.location.href="/web/home"
      }
    } else if (result?.data?.message === "Invalid token") {
      if (result?.data?.Type_Of_User === "Super Admin") {
        localStorage.removeItem("GFX_TOKEN")
        localStorage.setItem("GFX_Dashboard", "false") //need to check if error happened
        yield put({
          type: userAuthActions?.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
          payload: { isAuthenticated: false },
        })
        window.location.href='/login'
      }
      if (result?.data?.Type_Of_User === "Content Creator") {
        localStorage.removeItem("GFX_TOKEN2")
        localStorage.setItem("GFX_Dashboard", "false") // need to check if error happened
        yield put({
          type: userAuthActions?.UPDATE_CREATOR_AUTH_DETAILS,
          payload: { isAuthenticated: false },
        })
        window.location.href='/login'
      }
      if (result?.data?.Type_Of_User === "Admin") {
        localStorage.removeItem("GFX_TOKEN3")
        localStorage.setItem("GFX_Dashboard", "false") //need to check if error happened
        yield put({
          type: userAuthActions?.UPDATE_ADMIN_AUTH_DETAILS,
          payload: { isAuthenticated: false },
        })
        window.location.href='/login'
      }
      if (result?.data?.Type_Of_User === "User") {
        localStorage.removeItem("WEB_TOKEN")
        yield put({
          type: userAuthActions?.UPDATE_AUTH_DETAILS,
          payload: { isAuthenticated: false },
        })
        window.location.href='/'
      }
    }
  } catch (err) {
    console.log(err)
  }
}

const forgotPassword = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/forgetPassword`, payload)
    )
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    yield put({
      type: userAuthActions.USER_AUTH_RESPONSE,
      payload: result?.data,
    })
    yield delay(2000)
    yield put({ type: userAuthActions.USER_AUTH_RESPONSE, payload: null })
  } catch (err) {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    console.log(err)
  }
}

const getResetYourPassword = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/resetPassword/${payload.Id}`, payload.payload)
    )
    yield put({
      type: userAuthActions.SET_RESET_YOUR_PASSWORD,
      payload: result?.data,
    })

    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })
    }
  } catch (err) {
    console.error(err)
  }
}
