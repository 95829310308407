import React, { useState, useEffect } from "react"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa"
import { useForm } from "react-hook-form"
import { Row, Col, Form, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { userAuthActions } from "store/userAuth/actions"

const ChangePasswordindex = () => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const [showPassword, setShowPassword] = useState(false)
  const { creatorTokenResponse } = useSelector(state => state.userAuthReducer)
  const { superAdminTokenResponse } = useSelector(state => state.userAuthReducer)
  const { adminTokenResponse } = useSelector(state => state.userAuthReducer)

  const [id, setId] = useState(null)
  const [typeOfUser, setTypeOfUser] = useState(null)
  const [password, setPassword] = useState(null)

  useEffect(() => {
    if (
      superAdminTokenResponse &&
      superAdminTokenResponse.result &&
      superAdminTokenResponse.result._id
    ) {
      setId(superAdminTokenResponse.result._id)
      setTypeOfUser(superAdminTokenResponse.result.Type_Of_User)
    } else if (
      creatorTokenResponse &&
      creatorTokenResponse?.result &&
      creatorTokenResponse?.result?._id
    ) {
      setId(creatorTokenResponse.result._id)
      setTypeOfUser(creatorTokenResponse.result.Type_Of_User)
    } else if (adminTokenResponse && adminTokenResponse?.result && adminTokenResponse?.result?._id) {
      setId(adminTokenResponse?.result?._id)
      setTypeOfUser(adminTokenResponse?.result?.Type_Of_User)
    } else {
      setId(null)
      setTypeOfUser(null)
    }
  }, [superAdminTokenResponse, creatorTokenResponse, adminTokenResponse])

  const onSubmit = values => {
    const password = watch("Password")
    const confirmPassword = watch("ConfirmPassword")

    let data = {
      Id: id,
      payload: {
        Type_Of_User: typeOfUser,
        Password: password,
      },
    }

    dispatch({
      type: userAuthActions.GET_RESET_YOUR_PASSWORD,
      payload: data,
    })
  }

  return (
    <div>
      <Row className="form-group-row">
        <Col>
          <div className="change-password-heading">
            <h4>Change Password</h4>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col md={6}>
              <div className="changepassword-container">
                <Form.Label className="form-label">Reset your password</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  className={
                    errors.Password?.message
                      ? "required-field web_fonts formcontrol"
                      : "web_fonts formcontrol"
                  }
                  {...register("Password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/,
                      message:
                        "Minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character and must not including spaces",
                    },
                  })}
                />
                <span
                  className="reset-password-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaRegEye className="pass-i" /> : <FaRegEyeSlash className="pass-i" />}
                </span>
                <p className="error-message">{errors.Password?.message}</p>
              </div>
            </Col>

            <Col md={6}>
              <div className="confirmpassword-container">
                <Form.Label className="form-label">Confirm your password</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  className={
                    errors.ConfirmPassword?.message
                      ? "required-field web_fonts formcontrol"
                      : "web_fonts formcontrol"
                  }
                  {...register("ConfirmPassword", {
                    required: "Password confirmation is required",
                    validate: (value) => value === watch("Password") || "Passwords do not match",
                  })}
                />
                <span
                  className="confirm-reset-password-icon custom-nav-link"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaRegEye className="pass-i" /> : <FaRegEyeSlash className="pass-i" />}
                </span>
                <p className="error-message">{errors.ConfirmPassword?.message}</p>
              </div>
            </Col>

            <Row>
              <Col>
                <Button
                  color="primary"
                  className="Primary confirmpassword-container submit-button"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>

        </Col>
      </Row>
    </div>
  )
}

export default ChangePasswordindex
