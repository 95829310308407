import React, { useEffect, useState } from "react";
import Header from "../../webComponents/Layout/Header";
import Footer from "../../webComponents/Layout/Footer";
import ContentByCategoryindex from "../../webComponents/ContentByCategory/index";
import { useParams } from "react-router-dom";
import HrBanner from "webComponents/Common/FreeTrailBannerImg";
import Banner1 from "../../assets/webImages/Horizontal banner-1.webp";
import Banner2 from "../../assets/webImages/HR banner-darkblue.webp";
import Banner3 from "../../assets/webImages/Horizontal banner-2.webp";
import { useDispatch, useSelector } from "react-redux";
import { WebHomePageActions } from "store/webHome/actions";
import SubcribeBanner1 from "../../assets/webImages/LK skyblue,white.webp";
import SubcribeBanner2 from "../../assets/webImages/LK white,blue.webp";
import SubcribeBanner3 from "../../assets/webImages/LK skyblue,black.webp";
import SubscribeBanner4 from "../../assets/webImages/LK-1 darkblue.webp";
import WebPageLoader from "../../common/webPageLoader";

export default function ContentByCategory() {
  const { category } = useParams();
  const dispatch = useDispatch();
  const { tokenResponse } = useSelector((state) => state.userAuthReducer);

  let userId = tokenResponse?.result?._id;
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes";
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes";
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id;

  const [loading, setLoading] = useState(false);

  const { getSessionId } = useSelector(state => state.subscriptionPlanReducer);

  useEffect(() => {
    const payload = { userId };
    if (userId) {
      dispatch({
        type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
        payload,
      });
    }
  }, [dispatch, userId]);

  let bannerImage;

  useEffect(() => {
    if (getSessionId) {
      setLoading(false);
    }
  }, [getSessionId])

  switch (category) {
    case "Business":
      bannerImage = Banner3;
      break;
    case "Politics":
      bannerImage = Banner2;
      break;
    case "Green":
      bannerImage = Banner1;
      break;
    case "In Depth":
      bannerImage = Banner3;
      break;
    default:
      bannerImage = Banner2;
  }

  let subscriptionBanner;

  if (isSubscribed || isTrialUsed || stripeCutomerId) {
    switch (category) {
      case "Business":
        subscriptionBanner = SubcribeBanner1;
        break;
      case "Politics":
        subscriptionBanner = SubcribeBanner2;
        break;
      case "Green":
        subscriptionBanner = SubcribeBanner3;
        break;
      case "In Depth":
        subscriptionBanner = SubscribeBanner4;
        break;
    }
  }

  return (
    <>
      {loading ? <WebPageLoader /> :
        <div>
          <Header />
          {isSubscribed || isTrialUsed || stripeCutomerId ? (
            <HrBanner bannerImage={subscriptionBanner} bannerLink="/" bannerType="subscribed" setLoading={setLoading} />
          ) : (
            <HrBanner bannerImage={bannerImage} bannerLink="/" bannerType="freeTrial" setLoading={setLoading} />
          )}
          <ContentByCategoryindex />
          <Footer />
        </div>
      }
    </>
  );
}
