import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
    const navigate = useNavigate();

    const handleBack = (event) => {
        event.preventDefault();
        navigate('/');
    };

    return (
        <div id="error-page">
            <div className="contentnotfound">
                <h2 className="pagenotfound" data-text="404">404</h2>
                <h4 className="oopstext" data-text="Oops! Page not found">Oops! Page not found</h4>
                <p className='paranotfound'>Sorry, the page you're looking for doesn't exist.</p>
                <div className="btns">
                    <a href='#' onClick={handleBack}>Return Home</a>
                </div>
            </div>
        </div>
    );
}
