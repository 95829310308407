import React, { useEffect, useState } from "react"
import { Row, Col, Form, Button, FormControl, Spinner } from "react-bootstrap"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { GoArrowLeft } from "react-icons/go"
import Registerimage from "../../assets/webImages/signupimage.webp"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import { useNavigate } from "react-router-dom"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { SignUpActions } from "../../store/webAuth/actions"
import { BlogContentActions } from "store/blogContent/actions"
import Select from "react-select"
import { SignUpReducer } from "store/webAuth/reducer"
import success_img from "../../assets/images/dashboard/success1-popup-image.gif"
// import success_img from "../../assets/images/dashboard/success-tickmark.gif";
import failure_img from "../../assets/images/dashboard/failure-popup-image.gif"
import { actions } from "store/Countries/actions"

const options = [
  { value: "Business", label: "Business" },
  { value: "Politics", label: "Politics" },
  { value: "Green", label: "Green" },
  // { value: "Topic2", label: "Topic 2" },
  { value: "In depth", label: "In-Depth" },
]

const Signup = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //  form fields and validation
  const [First_Name, setFirstName] = useState("")
  const [Last_Name, setLastName] = useState("")
  const [Email, setEmail] = useState("")
  const [Contact_Number, setContact_Number] = useState("")
  const [Password, setPassword] = useState("")
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [Confirm_Password, setConfirmPassword] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([])
  const [showPrefErr, setShowPrefErr] = useState("")
  const [showPasswordfErr, setShowPasswordErr] = useState("")
  const [countryListOptions, setCountryListOptions] = useState([])
  // Validation functions

  const capitalizeFirstLetter = (value) => {
    return value.replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  };

  const isFirstNameValid = () => First_Name.trim().length > 0;
  const isLastNameValid = () => Last_Name.trim().length > 0;

  const handleFirstNameChange = (e) => {
    const formattedValue = capitalizeFirstLetter(e.target.value);
    setFirstName(formattedValue);
  };

  const handleLastNameChange = (e) => {
    const formattedValue = capitalizeFirstLetter(e.target.value);
    setLastName(formattedValue);
  };
  const isEmailValid = () =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email) || !isFormSubmitted

  // const isContact_NumberValid = () =>
  //   /^[0-9]{10}$/.test(Contact_Number) || !isFormSubmitted

  const isContact_NumberValid = () =>
    (Contact_Number.trim() !== '') || !isFormSubmitted;

  const isPasswordValidFunc = () =>
    (Password?.trim() !== "" && validatePassword(Password)) || !isFormSubmitted
  const validatePassword = Password => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
    return regex.test(Password)
  }
  const isConfirmPasswordValid = () => {
    // Check if Confirm_Password is a string and not empty
    return (typeof Confirm_Password === 'string' && Confirm_Password.trim() !== "") &&
      validateConfirmPassword(Confirm_Password);
  };

  const validateConfirmPassword = Confirm_Password => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return regex.test(Confirm_Password);
  };

  const customStyles = {
    menu: provided => ({
      ...provided,
      backgroundColor: 'white', // Set the desired background color
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set the desired height for the dropdown
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === 'cocoa' || state.data.value === 'oil' || state.data.value === 'gold' ? 'blue' : '',
      // Change font color for specific options
    }),
  };

  const navigateToLogin = () => {
    navigate("/web/login")
  }

  const { userResponse } = useSelector(state => state.SignUpReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer)
  const { countryList } = useSelector(state => state.CountryReducer)
  // const [isChecked, setIsChecked] = useState(false);
  // const [showCheckErr, setShowCheckErr] = useState("")

  const [showSignupPopup, setShowSignupPopup] = useState({
    isOpen: false,
    statusCode: null,
  })

  // const handleCheckboxChange = (e) => {
  //   setIsChecked(e.target.checked);
  // };
  const handlePasswordChange = e => {
    const newPassword = e.target.value.trim()
    setPassword(newPassword)
    const isValid = validatePassword(newPassword)
    setIsPasswordValid(isValid)
  }

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value.trim());
  };

  const isPasswordMatch = () => {
    return Password === Confirm_Password;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleMultiSelectChange = selected => {
    setSelectedOptions(selected)
  }
  const register = e => {
    e.preventDefault()
    setIsFormSubmitted(true)
    setIsSubmitted(true);
    let emailValid = isEmailValid()
    let contactNumberValid = isContact_NumberValid()
    let passwordValidateFunc = isPasswordValidFunc()
    let passwordMatch = isPasswordMatch();
    let PreferenceArray = []
    if (selectedOptions?.length === 0) {
      PreferenceArray = []
      setShowPrefErr("Please select your preference")
    } else {
      setShowPrefErr("")
      selectedOptions?.map(single => PreferenceArray.push(single.value))
    }

    // if (isPasswordValid === false) {
    //   setShowPasswordErr("Please enter strong password")
    // } else {
    //   setShowPasswordErr("")
    // }

    // if (isChecked === false) {
    //   setShowCheckErr("Please confirm this checkbox")
    // } else {
    //   setShowCheckErr('')
    // }
    if (
      First_Name &&
      Last_Name &&
      Email &&
      Contact_Number &&
      Password &&
      passwordMatch &&
      emailValid &&
      contactNumberValid &&
      passwordValidateFunc &&
      PreferenceArray?.length !== 0
      // PreferenceArray?.length !== 0 && isChecked
    ) {
      const data = {
        First_Name: First_Name,
        Last_Name: Last_Name,
        Email: Email.toLowerCase(),
        Contact_Number: Contact_Number,
        Password: Password,
        Type_Of_User: "User",
        Preferences: PreferenceArray,
      }
      dispatch({
        type: SignUpActions.GET_WEBUSER_SIGNUP_DETAILS,
        payload: data,
      })
    }
  }

  useEffect(() => {
    if (selectedOptions?.length !== 0) {
      setShowPrefErr("")
    }
  }, [selectedOptions])

  useEffect(() => {
    let passwordValidateFunc = isPasswordValidFunc()
    if (passwordValidateFunc === false && Password?.length >= 8) {
      setShowPasswordErr("")
    }
  }, [Password])

  useEffect(() => {
    if (userResponse?.status === "success") {
      setFirstName("")
      setLastName("")
      setEmail("")
      setContact_Number("")
      setPassword("")
      setIsFormSubmitted(false)
      setShowPassword(false)
      setConfirmPassword("")
      setIsSubmitted(false);
      setSelectedOptions([])
      setShowPrefErr("")
      setShowPasswordErr("")
      setShowSignupPopup({ isOpen: true, statusCode: 200 })
      // setIsChecked(false)
      // setShowCheckErr("")
    } else if (userResponse?.status === "failed") {
      setShowSignupPopup({ isOpen: true, statusCode: 400, message: userResponse?.message })
    }
  }, [userResponse])

  const handleClose = () => {
    setShowSignupPopup({ isOpen: false, statusCode: null })
  }

  const handleCloseSuccess = () => {
    setShowSignupPopup({ isOpen: false, statusCode: null })
    // window.location.href = '/register'
  }

  useEffect(() => {
    dispatch({ type: actions.GET_COUNTRY_LIST })
  }, [])

  useEffect(() => {
    if (countryList?.result?.length !== 0) {
      let listArray = []
      countryList?.result?.map(single =>
        listArray.push({
          value: single?.Country_Name,
          label: single?.Country_Name,
        })
      )
      setCountryListOptions(listArray)
    }
  }, [countryList])

  return (
    <React.Fragment>
      <div className="login-wrap">
        <div className="row no-space">
          {/* 70% column */}
          {/* <Col lg={8} md={9} className="signup-leftside"> */}
          <div className="col-md-7 signup-leftside ">
            <div className="login-test">
              <Col>
                <img src={Logoimage} alt="logo" className="logo-ad" onClick={() => navigate('/')} />
              </Col>

              <Col className="signup-container">

                <p
                  className="back-to-login web_fonts pt-1"
                  onClick={navigateToLogin}
                >
                  <GoArrowLeft /> back to log in
                </p>

                <h5 className="signup-header web_fonts">create your account</h5>
                <p className="signup-description web_fonts">
                  please enter your details below we will create your account
                </p>

                <Row className="form-group-row">
                  <Col md={6}>
                    <Form.Group
                      controlId="formFirstName"
                      className="form-group"
                    >
                      <Form.Label className="form-label web-form-label web_fonts">
                        first name
                      </Form.Label>
                      <span className="text-danger"> *</span>
                      <Form.Control
                        className="formcontrol"
                        type="text"
                        placeholder=""
                        value={First_Name}
                        onChange={handleFirstNameChange}
                        isInvalid={!isFirstNameValid() && isFormSubmitted}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please enter your first name
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formLastName" className="form-group">
                      <Form.Label className="form-label web-form-label web_fonts">
                        last name
                      </Form.Label>
                      <span className="text-danger"> *</span>
                      <Form.Control
                        className="formcontrol"
                        type="text"
                        placeholder=""
                        value={Last_Name}
                        onChange={handleLastNameChange}
                        isInvalid={!isLastNameValid() && isFormSubmitted}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your last name
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="form-group-row">
                  <Col md={6}>
                    <Form.Group controlId="formEmail" className="form-group">
                      <Form.Label className="form-label web-form-label web_fonts">
                        email address
                      </Form.Label>
                      <span className="text-danger"> *</span>
                      <Form.Control
                        className="formcontrol"
                        type="email"
                        placeholder=""
                        value={Email}
                        onChange={e => setEmail(e.target.value.trim())}
                        isInvalid={!isEmailValid() && isFormSubmitted}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      controlId="formPhoneNumber"
                      className="form-group"
                    >
                      <Form.Label className="form-label web-form-label web_fonts">
                        phone number
                      </Form.Label>
                      <span className="text-danger"> *</span>
                      <Form.Control
                        className="formcontrol"
                        type="tel"
                        placeholder=""
                        value={Contact_Number}
                        onChange={e => setContact_Number(e.target.value)}
                        isInvalid={!isContact_NumberValid() && isFormSubmitted}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Contact_Number?.trim() === ""
                          ? "Please enter your phone number."
                          : "Please enter a valid phone number."}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="form-group-row">
                  <Col md={6}>
                    <Form.Group controlId="formPassword" className="form-group">
                      <Form.Label className="form-label web-form-label web_fonts">
                        password
                      </Form.Label>
                      <span className="text-danger"> *</span>
                      <div className="password-container">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          className="formcontrol"
                          placeholder="Password"
                          value={Password}
                          onChange={handlePasswordChange}
                          isInvalid={!isPasswordValidFunc()}
                        />
                        <Form.Control.Feedback type="invalid" className="errorFontsize">
                          {Password?.trim() === ""
                            ? "Please enter your password"
                            : "Please enter strong password"}
                        </Form.Control.Feedback>
                        <span
                          className="password-icon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <FaRegEye className="pass-i" />
                          ) : (
                            <FaRegEyeSlash className="pass-i" />
                          )}
                        </span>
                      </div>
                      <small className="text-danger">{showPasswordfErr}</small>

                      {/* <div className="password-container">
                        <Form.Check
                          type="checkbox"
                          label="Minimum 8 characters"
                          checked={Password?.length >= 8}
                          className="password-check min-8-chars web_fonts"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Maximum 50 characters"
                          checked={Password?.length <= 50}
                          className="password-check web_fonts"
                        />
                        <Form.Check
                          type="checkbox"
                          label="1 uppercase letter"
                          checked={/[A-Z]/.test(Password)}
                          className="password-check web_fonts"
                        />
                        <Form.Check
                          type="checkbox"
                          label="1 lowercase letter"
                          checked={/[a-z]/.test(Password)}
                          className="password-check web_fonts"
                        />
                        <Form.Check
                          type="checkbox"
                          label="1 number"
                          checked={/\d/.test(Password)}
                          className="password-check web_fonts"
                        />
                        <Form.Check
                          type="checkbox"
                          label="1 special character (not including spaces)"
                          checked={/[!@#$%^&*(),.?":{}|<>]/.test(Password)}
                          className="password-check web_fonts"
                        />
                      </div> */}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPassword" className="form-group">
                      <Form.Label className="form-label web-form-label web_fonts">Confirm Password</Form.Label>
                      <span className="text-danger"> *</span>
                      <div className="password-container">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          className="formcontrol"
                          placeholder="Confirm Password"
                          value={typeof Confirm_Password === 'string' ? Confirm_Password : ''}
                          onChange={handleConfirmPassword}
                          isInvalid={isSubmitted && (!Confirm_Password || !isPasswordMatch())}
                        />
                        <Form.Control.Feedback type="invalid" className="errorFontsize">
                          {Confirm_Password && typeof Confirm_Password === 'string' && Confirm_Password.trim() === ""
                            ? "Please enter your confirm password"
                            : "Password does not match"}
                        </Form.Control.Feedback>
                        <span className="password-icon" onClick={togglePasswordVisibility}>
                          {showPassword ? (
                            <FaRegEye className="pass-i" />
                          ) : (
                            <FaRegEyeSlash className="pass-i" />
                          )}
                        </span>
                      </div>
                      <small className="text-danger errorFontsize">{showPasswordfErr}</small>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="prefrencecol">
                    <Form.Group controlId="formLastName" className="form-group">
                      <Form.Label className="form-label web-form-label web_fonts">
                        Market focus
                      </Form.Label>
                      <span className="text-danger"> *</span>

                      <Select
                        isMulti
                        options={countryListOptions}
                        styles={customStyles}
                        value={selectedOptions}
                        onChange={handleMultiSelectChange}
                        placeholder="Select your preference..."
                      />
                      <small className="text-danger ">{showPrefErr}</small>

                    </Form.Group>
                  </Col>
                </Row>
                {/* <div className="agecheck">
                  <Form.Check
                    type="checkbox"
                    label="I confirm that I am 16 years or older"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className='text-trans pt-2 pb-2 ml-5 text-primary'
                  />
                </div> */}

                {/* <small className="text-danger">{isChecked === false && showCheckErr}</small> */}
                <p className="terms-policy web_fonts">
                  By creating an account, you confirm you are 16 years of age or older.</p>
                <p className="terms-policy web_fonts">
                  By continuing, you agree to allen dreyfus{" "}
                  <span
                    className="terms-link web_fonts"
                    onClick={() => navigate("/terms-of-use")}
                  >
                    terms of use
                  </span>
                  {"   "}
                  and{"   "}
                  <span
                    className="policy-link web_fonts"
                    onClick={() => navigate("/privacy-notice")}
                  >
                    privacy notice
                  </span>


                  <div className="cre-acc-but-div">
                    <Button
                      type="button"
                      className="create-account-button web_fonts"
                      onClick={e => register(e)}
                    >
                      {buttonLoader ? (
                        <Spinner color="light" size="sm" />
                      ) : (
                        "create account"
                      )}
                    </Button>
                  </div>
                </p>



              </Col>
            </div>
          </div>
          {/* </Col> */}

          {/* 30% column */}
          {/* <Col lg={4} md={12} className="signup-col"> */}
          <div className="col-md-5 signup-img-bg">
            <div className="lgo-img ">
              <img
                className="signupimg"
                src={Registerimage}
                alt="registerimage"
              />

              {/* </Col> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showSignupPopup?.isOpen}
        toggle={
          showSignupPopup?.statusCode === 200 ? handleCloseSuccess : handleClose
        }
        centered={true}
      >
        <ModalHeader
          centered={true}
          toggle={
            showSignupPopup?.statusCode === 200
              ? handleCloseSuccess
              : handleClose
          }
          tag="h4"
        >
          <h4>Alert</h4>
        </ModalHeader>
        <ModalBody centered={true}>
          <Form className="padding-signup-model">
            {showSignupPopup?.statusCode == 200 ? (
              <Row>
                <Col md={12} className="text-center">
                  <img
                    src={success_img}
                    alt="success_popup"
                    className="popup-success"
                  />
                  <p>
                    Thank you for your registration!
                    <br />
                    You will get credentials through email associated with your
                    account within two minutes
                  </p>
                </Col>
                <hr />

                <Col md={12}>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-danger save-customer me-2"
                      // onClick={(handleClose)}
                      onClick={handleCloseSuccess}
                    >
                      Close
                    </button>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={12} className="mb-4 mt-3 text-center">
                  <img
                    src={failure_img}
                    alt="failure_popup"
                    className="popup-img"
                  />
                  <h5>{showSignupPopup?.message}</h5>
                </Col>
                <hr />

                <Col md={12}>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary save-customer me-2"
                      onClick={handleClose}
                    >
                      Ok
                    </button>
                  </div>
                </Col>
              </Row>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Signup
