import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import profileImg from "../assets/images/profile-img.png";
import logosm from "../assets/images/dashboard/logo-small.png";
import profileicon from '../assets/images/dashboard/user3.png';
import { useFormik } from "formik";
import * as Yup from "yup";

export default function LockScreen() {

  //meta title
  document.title = "Lock Screen | Allen Dreyfus";

  // const { tokenResponse } = useSelector(state => state.userAuthReducer);
  const { superAdminTokenResponse, creatorTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);

  const navigate = useNavigate();

  const [passErr, setPassErr] = useState('');

  useEffect(() => {
    if (!localStorage.getItem("GFX_Dashboard") || (localStorage.getItem("GFX_Dashboard") === "true")) {
      navigate('/login');
    };
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      if (values?.password === (superAdminTokenResponse?.result?.Password || creatorTokenResponse?.result?.Password || adminTokenResponse?.result?.Password)) {
        localStorage.setItem('GFX_Dashboard', "true");
        navigate('/login');
      } else {
        setPassErr('Incorrect password');
      };
    }
  });

  const handleRedirectToLogin = (e) => {
    e.preventDefault();
    {
      superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin' &&
        localStorage.removeItem('GFX_TOKEN');
    }
    {
      creatorTokenResponse?.result?.Type_Of_User === 'Content Creator' &&
        localStorage.removeItem('GFX_TOKEN2');
    }
    {
      adminTokenResponse?.result?.Type_Of_User === 'Admin' &&
        localStorage.removeItem('GFX_TOKEN3')
    }
    localStorage.setItem('GFX_Dashboard', "true");
    navigate('/login');
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Lock screen</h5>
                        <p>Enter your password to unlock the screen!</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logosm}
                            alt=""
                            className="rounded-circle logo-sm-img-login"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="user-thumb text-center mb-4">
                        <img
                          // src={tokenResponse?.result?.Profile_Image ?? profileicon}
                          // src={(superAdminTokenResponse?.result?.Password || creatorTokenResponse?.result?.Password) ?? profileicon}
                          src={(superAdminTokenResponse?.result?.Profile_Image || creatorTokenResponse?.result?.Profile_Image || adminTokenResponse?.result?.Profile_Image) ?? profileicon}
                          className="rounded-circle img-thumbnail avatar-md"
                          alt="thumbnail"
                        />
                        <h5 className="font-size-15 mt-3">{(superAdminTokenResponse || creatorTokenResponse || adminTokenResponse)?.result?.Name}</h5>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          // onChange={(e) => {
                          //   setPassErr('');
                          //   validation.handleChange(e)
                          // }}
                          onChange={(e) => {
                            setPassErr('');
                            validation.handleChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value.trim(),
                              },
                            });
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            ((validation.touched.password && validation.errors.password) || passErr) ? true : false
                          }
                        />
                        {(validation.touched.password && validation.errors.password) ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) :
                          passErr ? (
                            <FormFeedback type="invalid">{passErr}</FormFeedback>
                          ) :
                            null
                        }
                      </div>

                      <div className="text-end">
                        <Col xs="12" className="text-end">
                          <Button
                            color="primary"
                            className=" w-md "
                            type="submit"
                          >
                            Unlock
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Not you ? return{" "}
                  <Link
                    onClick={handleRedirectToLogin}
                    to="#"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Sign In{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {(new Date().getFullYear())} Allen Dreyfus
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
