import React, { useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Subscriptionimage from "../SubscribeBanner/index"
import AudioPlayer from "react-audio-player"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"
import NewsletterSectionCommon from "webComponents/Common/newslettercommon"
import NewsletterText from "../../assets/webImages/exclusive market (2).webp"
import NewsletterMsgImg from "../../assets/webImages/Group 7.webp"
import Subscribenow from "../../assets/webImages/subscribenow.svg"
import Subscriptiontext from "../../assets/webImages/real-time1.webp"
import { HiVolumeUp } from "react-icons/hi"

const Foryou = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [showAudioPlayer, setShowAudioPlayer] = useState(false)
  const [showVolumeIcon, setShowVolumeIcon] = useState(true)
  const [activeAudioPlayer, setActiveAudioPlayer] = useState(null)

  const handleListenButtonClick = audioPlayerId => {
    setActiveAudioPlayer(audioPlayerId)
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { limitedRecentContent, PopularNews, PodcastListForHomePage, LimitedForUList } = useSelector(state => state.WebHomePageReducer)

  const isLoggedIn = !!tokenResponse?.result
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, {
      state: { contentDetail: contentDetail },
    })
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
      payload: contentDetail._id,
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  //get podcast list for home page
  useEffect(() => {
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_SPECIFIC_PODCAST_HOME,
    })
  }, [dispatch])

  //list based on user preference in For u section
  useEffect(() => {
    const UserId = tokenResponse?.result?._id
    const TypeOfContent = "Article"
    if (UserId) {
      dispatch({
        type: WebHomePageActions.GET_LIMITED_FORU_LIST,
        payload: { UserId, TypeOfContent },
      })
    }
  }, [tokenResponse])

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
    setShowAudioPlayer(true)
    setShowVolumeIcon(false)
  }

  const handleStop = () => {
    setIsPlaying(false)
    setShowAudioPlayer(false)
    setShowVolumeIcon(true)
  }
  //populer news in home page
  useEffect(() => {
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_POPULARNEWS_DETAILS,
    })
  }, [dispatch])

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  const handlePodcastClick = () => {
    navigate("/web/Podcast")
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleSeeAllClick = () => {
    if (isLoggedIn && LimitedForUList?.result?.length > 0) {
      navigate("/web/foryou")
    } else {
      navigate("/web/login")
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Container>
      <div className="recent-heading-for-you">
        <h2 className="web_fonts">for you </h2>
      </div>
      <Row className="foryou-podcast-fullrow">
        <Col xs={12} md={8}>
          <Row>
            <Col xs={9} md={6}>
              <h5 className="web_fonts color-black sub-heading-for d-flex">
                tailored reading selections
              </h5>
            </Col>
            {/* <Col xs={3} md={6}>
              {isLoggedIn && ForYouSpecificContent?.result?.length > 0 && (
                <Button
                  className="web_fonts seeall-foru"
                  onClick={handleSeeAllClick}
                >
                  see all
                </Button>
              )}
            </Col> */}
            <Col xs={3} md={6}>
              <Button
                className="web_fonts seeall-foru"
                onClick={handleSeeAllClick}
              >
                see all
              </Button>
            </Col>
          </Row>
          <Row className="foryou-big-img">
            {isLoggedIn && LimitedForUList?.statusCode !== 204
              ? LimitedForUList?.result
                ?.slice(0, 2)
                .map((newsItem, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={6}
                    onClick={() => handleSectionClick(newsItem)}
                  >
                    <div className="foryou-cont">
                      <img
                        src={newsItem?.Article_Images}
                        alt={`Image ${index + 1}`}
                      />
                      <div className="foryou-text">
                        <p
                          style={{ color: "#ffffff", fontWeight: "bold" }}
                          className="web_fonts p-left"
                        >
                          {newsItem?.Category[0] === 'Recent Topic' ? newsItem?.Sub_Category[0] : newsItem?.Category[0]}
                        </p>
                        <h6 className="text-heading web_fonts">
                          {newsItem?.Title?.length > 50
                            ? `${newsItem?.Title?.slice(0, 50)}...`
                            : newsItem?.Title}
                        </h6>
                        <p className="foryou-author web_fonts">
                          by{" "}
                          <span
                            className="text-name web_fonts"
                            style={{ textTransform: "none" }}
                          >
                            {newsItem?.Created_By}
                          </span>{" "}
                          {/* {newsItem?.Created_On} */}
                          {`${formatDate(newsItem.Approved_On)}`}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))
              : limitedRecentContent?.result?.slice(7, 9).map((story, index) => (
                <Col
                  key={story._id}
                  xs={12}
                  md={6}
                  onClick={() => handleSectionClick(story)}
                >
                  <div className="foryou-cont">
                    <img
                      src={story?.Article_Images}
                      alt={`Image ${index + 1}`}
                    />
                    <div className="foryou-text">
                      <p
                        style={{ color: "#ffffff", fontWeight: "bold" }}
                        className="web_fonts p-left"
                      >
                        {story?.Category[0] === 'Recent Topic' ? story?.Sub_Category[0] : story?.Category[0]}
                      </p>
                      <h6 className="text-heading web_fonts">
                        {story?.Title?.length > 70
                          ? `${story?.Title?.slice(0, 70)}...`
                          : story?.Title}
                      </h6>
                      <p className="foryou-author web_fonts">
                        by{" "}
                        <span
                          className="text-name web_fonts"
                          style={{ textTransform: "none" }}
                        >
                          {story?.Created_By}
                        </span>{" "}
                        {/* {story?.Approved_On} */}
                        {`${formatDate(story?.Approved_On)}`}
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>

          <Row className="foru-rowcolumns">
            {isLoggedIn && LimitedForUList?.statusCode !== 204
              ? LimitedForUList?.result
                ?.slice(2, 6)
                .map((newsItem, index) => (
                  <Col
                    key={index}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    onClick={() => handleSectionClick(newsItem)}
                  >
                    <div className="foru-rowcolumn-div">
                      <img
                        src={newsItem?.Article_Images}
                        alt={`Topic ${index + 3} Image`}
                        className="foru-rowcolumns-img"
                      />
                      <p
                        style={{ color: "#004aad", fontWeight: "bold" }}
                        className="web_fonts p-left"
                      >
                        {newsItem.Category}
                      </p>
                      <h6 className="web_fonts color-black text-trans">
                        {newsItem?.Title?.length > 50
                          ? `${newsItem?.Title?.slice(0, 30)}...`
                          : newsItem?.Title}
                      </h6>
                      <p className="disabled-text web_fonts">
                        {/* {newsItem?.Approved_On} */}
                        {`${formatDate(newsItem?.Approved_On)}`}
                      </p>
                    </div>
                  </Col>
                ))
              : limitedRecentContent?.result?.slice(9, 13).map((story, index) => (
                <Col
                  key={index}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  onClick={() => handleSectionClick(story)}
                >
                  <div className="foru-rowcolumn-div">
                    <img
                      src={story?.Article_Images}
                      alt={`Topic ${index + 1} Image`}
                      className="foru-rowcolumns-img"
                    />
                    <p
                      style={{ color: "#004aad", fontWeight: "bold" }}
                      className="web_fonts p-left"
                    >
                      {story?.Category}
                    </p>
                    <h6 className="web_fonts color-black">
                      {story?.Title?.length > 50
                        ? `${story.Title.slice(0, 50)}...`
                        : story?.Title}
                    </h6>
                    <p className="disabled-text web_fonts">
                      {/* {story?.Approved_On} */}
                      {`${formatDate(story?.Approved_On)}`}
                    </p>
                  </div>
                </Col>
              ))}
          </Row>
          {/* podcasts */}
          <Row>
            <div className="recent-heading">
              <h1 className="web_fonts heading-for-website">podcasts</h1>
              <hr className="hr-line" />
              <button
                type="button"
                className="btn btn-primary podcast-seeall-btn"
                onClick={handlePodcastClick}
              >
                see all
              </button>
            </div>
            <h5 className="web_fonts color-black sub-heading-for">
              featured podcast discussions
            </h5>

            {PodcastListForHomePage?.result
              ? PodcastListForHomePage?.result
                ?.slice(0, 1)
                .map((podcast, index) => (
                  <Col xs={12} md={6} key={index}>
                    <div className="podcasts-big-img" onClick={handlePodcastClick}>
                      {showAudioPlayer && (
                        <Col xs={12} md={6} className="audioplay-div">
                          <AudioPlayer
                            src={podcast?.Podcast[0]}
                            autoPlay={false}
                            controls
                            // onPlay={handlePlayPause}
                            // onPause={handlePlayPause}
                            // onStop={handleStop}
                            className="audio-player"
                          />
                        </Col>
                      )}
                      <img
                        src={podcast?.Thumbnail_Image}
                        alt="Topic 1 Image"
                      // onClick={handlePlayPause}
                      />
                      {showVolumeIcon && (
                        <div className="volumeup">
                          <HiVolumeUp
                            className="volumeup-icon"
                          // onClick={handlePlayPause}
                          />
                        </div>
                      )}
                    </div>

                    <div>
                      <h5 className="podcast-big-img-text web_fonts">
                        {podcast?.Category[0]}
                      </h5>
                      <h4
                        className="web_fonts color-black politics-title"
                        onClick={handlePlayPause}
                      >
                        {podcast?.Title}
                      </h4>
                      <p className="disabled-text web_fonts">
                        by
                        <span
                          className="name-of web_fonts"
                          style={{ textTransform: "none" }}
                        >
                          {podcast?.Created_By}
                        </span>
                        {`${formatDate(podcast?.Approved_On)}`}
                      </p>
                    </div>
                  </Col>
                ))
              : null}

            <Col className="podcast-leftside" onClick={handlePodcastClick}>
              {PodcastListForHomePage?.result
                ? PodcastListForHomePage?.result
                  .slice(1, 4)
                  .map((podcast, index) => (
                    <Col xs={12} className="topic-column" key={index}>
                      <div className="topic-column-display pad-cst">
                        <div className="topic-column-dis-flex">
                          <p
                            style={{ color: "#004aad", fontWeight: "bold" }}
                            className="web_fonts p-left"
                          >
                            {podcast?.Category[0]}
                          </p>
                          <h6
                            className="content-heading web_fonts"
                            onClick={() => handleListenButtonClick(index + 1)}
                          >
                            {podcast?.Title}
                          </h6>
                          <p className="disabled-text web_fonts">
                            by
                            <span
                              className="name-of web_fonts"
                              style={{ textTransform: "none" }}
                            >
                              {podcast?.Created_By}
                            </span>
                            {`${formatDate(podcast?.Approved_On)}`}
                          </p>

                          {activeAudioPlayer === index + 1 && (
                            <Row>
                              <Col>
                                <AudioPlayer
                                  src={podcast?.Podcast[0]}
                                  autoPlay={isPlaying}
                                  controls
                                  className="small-podcast-audio"
                                />
                              </Col>
                            </Row>
                          )}
                        </div>

                        <div className="podcast-sm">
                          <div className="podcast-sm-img">
                            <img
                              src={podcast?.Thumbnail_Image}
                              alt={`Topic ${index + 2} Image`}
                            />
                            <div className="podcast-sm-vol">
                              <div>
                                <HiVolumeUp
                                  className="podcast-sm-vol-icon"
                                // onClick={() =>
                                //   handleListenButtonClick(index + 1)
                                // }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <hr className="hr-line-recent" />
                    </Col>
                  ))
                : null}
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={4}>
          <div>
            <Row>
              {isSubscribed || (isSubscribed && isTrialUsed) ? (
                <>
                  {/* <div className="sub-now">
                  <img src={SubcribeBanner}  className="insight-driven"/>
                 </div> */}
                </>
              ) : (
                <div className="sub-now">
                  <Subscriptionimage
                    Subscribenow={Subscribenow}
                    Subscriptiontext={Subscriptiontext}
                  />
                </div>
              )}

              <div className="recent-heading-foru">
                <h2 className="web_fonts">popular</h2>
                <hr className="hr-line" />
              </div>
              <h5 className="web_fonts color-black">reader's top picks</h5>

              {PopularNews?.result?.slice(0, 3).map((popular, index) => (
                <Col
                  key={index}
                  xs={12}
                  className="topic-column"
                  onClick={() => handleSectionClick(popular)}
                >
                  <div className="topic-column-display">
                    <div className="topic-column-dis-flex">
                      <p
                        style={{ color: "#004aad", fontWeight: "bold" }}
                        className="web_fonts p-left"
                      >
                        {popular?.Category}
                      </p>
                      <h6 className="content-heading web_fonts color-black">
                        {popular?.Title?.length > 50
                          ? `${popular.Title.slice(0, 50)}...`
                          : popular.Title}
                      </h6>
                    </div>

                    <div className="topic-column-dis-image ">
                      <img
                        src={popular?.Article_Images}
                        alt={`Topic ${index + 1} Image`}
                        className="topic-column-dis-image-img"
                      />
                    </div>
                  </div>
                  <hr className="hr-line-recent" />
                </Col>
              ))}
              <Col xs={12} className="news-let web_fonts">
                {/* <NewsletterSection /> */}
                <div
                  style={{
                    "--newsletter-bg-color": "white",
                    "--newsletter-text-color": "#000000",
                    "--newsletter-signup-text": "#004aad",
                    "--newsletter-border-bottom": "#004aad",
                  }}
                >
                  <NewsletterSectionCommon
                    NewsletterText={NewsletterText}
                    NewsletterMsgImg={NewsletterMsgImg}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default Foryou
