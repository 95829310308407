import React, { useEffect } from "react"
import Header from "../../webComponents/Layout/Header"
import Footer from "../../webComponents/Layout/Footer"
import HrBanner from "webComponents/Common/FreeTrailBannerImg"
import Banner1 from "../../assets/webImages/Horizontal banner-1.webp"
import SubscribedUserBanner from "../../assets/webImages/LK-1 darkblue.webp"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"
import Contactus from "../../webComponents/contactus/contactus"

export default function ContactUsIndex() {
  const dispatch = useDispatch()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  let userId = tokenResponse?.result?._id
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"

  document.title = "Contact Us"

  useEffect(() => {
    const payload = { userId }
    if (userId) {
      dispatch({
        type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
        payload,
      })
    }
  }, [dispatch, userId])

  return (
    <div>
      <Header />
      {/* {isSubscribed || isTrialUsed ? (
        <HrBanner bannerImage={SubscribedUserBanner} bannerLink="/" />
      ) : (
        <HrBanner bannerImage={Banner1} bannerLink="/" />
      )} */}
      <Contactus />
      <Footer />
    </div>
  )
}
