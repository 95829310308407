import React, { useEffect, useState } from "react"
import Header from "../../webComponents/Layout/Header"
import Footer from "../../webComponents/Layout/Footer"
import CountryListCategoryindex from "../../webComponents/ContentByCountry/index"
import HrBanner from "webComponents/Common/FreeTrailBannerImg"
import Banner1 from "../../assets/webImages/Horizontal banner-1.webp"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"
import SubscribedUserBanner from "../../assets/webImages/un darkblue.webp"
import WebPageLoader from "../../common/webPageLoader";

export default function ContentByCountry() {
  const dispatch = useDispatch()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)

  const [loading, setLoading] = useState(false);

  const { getSessionId } = useSelector(state => state.subscriptionPlanReducer);

  let userId = tokenResponse?.result?._id
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id;

  useEffect(() => {
    const payload = { userId }
    if (userId) {
      dispatch({
        type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
        payload,
      })
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (getSessionId) {
      setLoading(false);
    }
  }, [getSessionId])

  return (
    <>
      {loading ? <WebPageLoader /> :
        <div>
          <Header />
          {isSubscribed || isTrialUsed || stripeCutomerId ? (
            <HrBanner bannerImage={SubscribedUserBanner} bannerLink="/" bannerType="subscribed" setLoading={setLoading} />
          ) : (
            <HrBanner bannerImage={Banner1} bannerLink="/" bannerType="freeTrial" setLoading={setLoading} />
          )}
          <CountryListCategoryindex />
          <Footer />
        </div>
      }
    </>
  )
}
